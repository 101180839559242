/* .hidden-xxs */
@media only screen and (max-width: 368px) {
  .hidden-xxs {
    display: none !important;
  }

  .hero-title-custom {
    margin-right: 10px;
    font-size: 5.1rem;
  }

  .hero-image {
    width: 680px;
  }

  .bg-simple {
    background-image: none !important;
  }

  .hero-content {
    padding: 3rem !important;
  }
}

/* .hidden-xs */
@media only screen and (max-width: 576px) {
  .hidden-xs {
    display: none !important;
  }

  .hero-title-custom {
    margin-right: 10px;
    font-size: 5.1rem;
  }

  .hero-image {
    width: 680px;

  }

  .bg-simple {
    background-image: none !important;
  }
  
  .hero-content {
    padding: 3rem !important;
  }

}

/* .hidden-sm */
@media only screen and (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }

  .hero-title-custom {
    margin-right: 10px;
    font-size: 5.1rem;
  }

  .hero-image {
    width: 680px;
  }

  .bg-simple {
    background-image: none !important;
  }
  
  .hero-content {
    padding: 3rem !important;
  }

}

/* .hidden-md */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}

/* .hidden-lg */
@media only screen and (min-width: 992px) {
  .hidden-lg {
    display: none !important;
  }
}

/* .hidden-xl */
@media only screen and (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}

/* .hidden-xxl */
@media only screen and (min-width: 1441px) {
  .hidden-xxl {
    display: none !important;
  }
}