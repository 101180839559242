/* Page Fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400i,700i,900i');
@import url('https://fonts.googleapis.com/css?family=Sofia');
@import url('https://fonts.googleapis.com/css?family=Trirong');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Sacramento');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap');

.overflow-hidden {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch !important;
}


.brandColors {
    color: #0D6EFD;
    color: #d7e3f5;
    color: #EDEDF4;
    color: #0D757F;
    color: #FFE54C;
    color: #def3f6;
}

/*--------------------------------------------------------------
# Main

--------------------------------------------------------------*/
.underline-small {
    width: 5rem;
    border-bottom: #0D6EFD 5px solid;
    margin-top: -10px;
}

.nav-item.nav-link.active {
    border-bottom: 2px solid #0D6EFD;
  }

.hero-strategy-left {
    max-height: 700px;
    margin-left: -750px;
}

.section-separator {
    background-image: url('./img/separator.jpg');
    min-height: 190px;
    background-position: center;
    background-size: cover;
    width: 100%;
}

.text-bg {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 60px;
}

.bg-simple {
    background-image: url('./img/bg.png');
    position: relative;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.max-width {
    max-width: 1080px !important;
    margin: 0 auto !important;
    float: none !important;
}

.square-text {
    border-radius: 80px;
    min-height: 290px;
    background-color: #0D6EFD;
}

.square-cols {
    border-radius: 80px;
    width: 100%;
    height: 100%;
    min-height: 290px;
    background-color: #0D6EFD;
}

.hero-image {
    max-height: 700px;
    border-radius: 120px;
}

.back-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 36px !important;
    z-index: 999;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    color: #222222;
}

div {
    font-family: 'Montserrat', sans-serif;
}

.buttonIconBrands {
    min-width: 42px;
    min-height: 42px;
    padding: 6px 7px;
    margin: 0.5em;
}

.buttonIcon {
    min-width: 42px;
    min-height: 42px;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #ffffff00;
    padding: 6px 7px;
    margin: 0.5em;
    border-radius: 5px;
    background-color: #0D757F;
    color: white;
    display: inline-block;
    text-align: center !important;
    box-shadow: -0.07em -0.07em 0 #FFE54C;
}

.buttonIcon:hover {
    box-shadow: 0.2em 0.2em 0 #FFE54C;
    color: #fff;
}

.buttonGeneric {
    min-width: 200px;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #ffffff00;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: #0D757F;
    color: white;
    display: inline-block;
    text-align: center !important;
    box-shadow: -0.2em -0.2em 0 #FFE54C;
}

.buttonGeneric:hover {
    box-shadow: 0.4em 0.4em 0 #FFE54C;
    color: #fff;
}

.button-generic,
.button-generic:after {
    border: 2px solid #ffe44c00;
    border-radius: 5px;
    color: white !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.button-generic {
    letter-spacing: 1px;
    padding: 10px 15px;
    outline: 0;
    cursor: pointer;
    position: relative;
    background-color: #0d757f;
    -webkit-user-select: none;
    touch-action: manipulation;
    z-index: 10;
}

.button-generic:after {
    content: "";
    background-color: #FFE54C;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    transition: 0.2s;
}

.button-generic:hover:after {
    top: 7px;
    left: 7px;
}

.primary-title {
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
}

.navbar {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.blur {
    backdrop-filter: blur(15px);
}


.blur-container {
    padding: 0 0 2px 2px;
    border-radius: 20px;
}

.blur-background {
    background: #ffffff;
    filter: blur(20px);
    -webkit-filter: blur(20px);
    min-height: 50px;
}

.text-blur {
    margin-top: -50px !important;
}




/* Section Backgrounds */
.blog-bg::before {
    content: "";
    background-repeat: repeat;
    -webkit-filter: grayscale(70%);
    filter: grayscale(70%);
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.06;
}

/* Section Backgrounds End */


.back-to-top {
    position: fixed;
    display: none;
    width: 44px;
    height: 44px;
    padding: 8px 0;
    text-align: center;
    line-height: 1;
    font-size: 22px;
    right: 15px;
    bottom: 15px;
    z-index: 9;
}

.back-to-top i {
    color: #ffffff;
}

.back-to-top:hover i {
    color: #414141;
}

.hero-title-custom {
    font-size: 8rem;
    position: relative;
    margin-right: -300px;
}

/*-----------------------------*/
/******* Section Header ********/
/*-----------------------------*/
.section-header {
    position: relative;
    margin-bottom: 45px;
}

.section-header p {
    display: inline-block;
    margin: 0 30px;
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #ffffff;
}

.section-header p::before {
    position: absolute;
    content: "";
    height: 3px;
    top: 11px;
    right: 0;
    left: -30px;
    background: #0D6EFD;
    z-index: -1;
}

.section-header p::after {
    position: absolute;
    content: "";
    width: 3px;
    height: 3px;
    top: 11px;
    left: 3px;
    background: #0D6EFD;
    z-index: 1;
}


.section-header-custom-bg p {
    display: inline-block;
    margin: 0 30px;
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #def3f6;
}

.section-header-custom-bg p::before {
    position: absolute;
    content: "";
    height: 3px;
    top: 11px;
    right: 0;
    left: -30px;
    background: #0D6EFD;
    z-index: -1;
}

.section-header-custom-bg p::after {
    position: absolute;
    content: "";
    width: 3px;
    height: 3px;
    top: 11px;
    left: 3px;
    background: #0D6EFD;
    z-index: 1;
}

.section-header h2 {
    margin: 0;
    position: relative;
    font-size: 45px;
    font-weight: 700;
}

@media (max-width: 767.98px) {
    .section-header h2 {
        font-size: 30px;
    }
}


/*-----------------------------*/
/******* Experience CSS ********/
/*-----------------------------*/
.experience {
    position: relative;
    padding: 15px 0 15px 0;
}

.experience .timeline {
    position: relative;
    width: 100%;
}

.experience .timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background: #0D6EFD;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
}

.experience .timeline .timeline-item {
    position: relative;
    background: inherit;
    width: 50%;
    margin-bottom: 30px;
}

.experience .timeline .timeline-item.left {
    left: 0;
    padding-right: 30px;
}

.experience .timeline .timeline-item.right {
    left: 50%;
    padding-left: 30px;
}

.experience .timeline .timeline-item::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 48px;
    right: -8px;
    background: #ffffff;
    border: 2px solid #0D6EFD;
    border-radius: 16px;
    z-index: 1;
}

.experience .timeline .timeline-item.right::after {
    left: -8px;
}

.experience .timeline .timeline-item::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 46px;
    right: 10px;
    z-index: 1;
    border: 10px solid;
    border-color: transparent transparent transparent #d7e3f5;
}

.experience .timeline .timeline-item.right::before {
    left: 10px;
    border-color: transparent #d7e3f5 transparent transparent;
}

.experience .timeline .timeline-date {
    position: absolute;
    width: 100%;
    top: 44px;
    font-size: 16px;
    font-weight: 600;
    color: #0D6EFD;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;
}

.experience .timeline .timeline-item.left .timeline-date {
    text-align: left;
    left: calc(100% + 55px);
}

.experience .timeline .timeline-item.right .timeline-date {
    text-align: right;
    right: calc(100% + 55px);
}

.experience .timeline .timeline-text {
    padding: 30px;
    background: #ffffff;
    position: relative;
    border-right: 5px solid #d7e3f5;
    box-shadow: 0 0 60px rgba(0, 0, 0, .08);
}

.experience .timeline .timeline-item.right .timeline-text {
    border-right: none;
    border-left: 5px solid #d7e3f5;
}

.experience .timeline .timeline-text h2 {
    margin: 0 0 5px 0;
    font-size: 22px;
    font-weight: 600;
}

.experience .timeline .timeline-text h4 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
}

.experience .timeline .timeline-text p {
    margin: 0;
    font-size: 16px;
}

@media (max-width: 767.98px) {
    .experience .timeline::after {
        left: 8px;
    }

    .experience .timeline .timeline-item {
        width: 100%;
        padding-left: 38px;
    }

    .experience .timeline .timeline-item.left {
        padding-right: 0;
    }

    .experience .timeline .timeline-item.right {
        left: 0%;
        padding-left: 38px;
    }

    .experience .timeline .timeline-item.left::after,
    .experience .timeline .timeline-item.right::after {
        left: 0;
    }

    .experience .timeline .timeline-item.left::before,
    .experience .timeline .timeline-item.right::before {
        left: 18px;
        border-color: transparent #d7e3f5 transparent transparent;
    }

    .experience .timeline .timeline-item.left .timeline-date,
    .experience .timeline .timeline-item.right .timeline-date {
        position: relative;
        top: 0;
        right: auto;
        left: 0;
        text-align: left;
        margin-bottom: 10px;
    }

    .experience .timeline .timeline-item.left .timeline-text,
    .experience .timeline .timeline-item.right .timeline-text {
        border-right: none;
        border-left: 5px solid #d7e3f5;
    }
}


/*******************************/
/******* review CSS *******/
/*******************************/
.review {
    position: relative;
    margin: 0 45px 0;
    padding: 10px 0;
}

.review .container {
    max-width: 1000px;
}

.review .review-icon {
    margin-bottom: 45px;
    text-align: center;
}

.review .review-icon i {
    font-size: 60px;
    color: rgba(200, 231, 234, 1);
}

.review .review-item {
    position: relative;
    margin: 0 15px;
    text-align: center;
}

.review .review-img {
    position: relative;
    margin-bottom: 15px;
    z-index: 1;
}

.review-image-square {
    position: relative;
    z-index: 1;
}

.review-image-square .image-square {
    width: 96px;
    height: 96px;
    border-radius: 0.375rem;
    margin: 0 5px 20px -10px;
    box-shadow: 3px 3px 15px #cacaca;
}

.review .review-item img {
    margin: 0 auto;
    width: 120px;
    padding: 10px;
    border: 5px dotted #def3f6;
    border-radius: 100px;
}

.review .review-text {
    position: relative;
    margin-top: -70px;
    padding: 65px 35px 30px 35px;
    text-align: center;
    border-radius: 0.375rem;
}

.review .review-item p {
    font-size: 18px;
    font-style: italic;
    color: #444;
}

.review .review-text h3 {
    color: #0D6EFD;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.review .review-text h4 {
    color: #444;
    font-size: 14px;
    margin-bottom: 0;
}

.review .owl-dots {
    margin-top: 15px;
    text-align: center;
}

.review .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(256, 256, 256, .5);
}

.review .owl-dot.active {
    background: #ffffff;
}

@media (max-width: 575.98px) {
    .review {
        margin: 40px 2px 0;
        padding: 2px 0;
    }

    .review .review-item {
        margin: 0 5px 15px;
    }

    .review .review-text {
        margin-top: -70px;
        padding: 65px 15px 30px;
        position: relative;
    }
}



/*-----------------------------*/
/********* Footer CSS **********/
/*-----------------------------*/
.footer {
    position: relative;
    margin-top: 45px;
}

.footer-text {
    font-size: 13px;
    color: #222222;
}

.footer-link-uderline {
    border-bottom: 1px dashed;
}

.footer-links {
    font-size: 13px;
    color: #222222;
}

.footer-links:hover {
    color: #222222;
}

.footer .container-fluid {
    padding: 60px 0 0 0;
}

.footer .footer-info {
    position: relative;
    width: 100%;
    text-align: center;
}

.footer .footer-info h2 {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    color: #222222;
}

.footer .footer-info h3 {
    margin-bottom: 25px;
    font-size: 22px;
    font-weight: 600;
    color: #222222;
}

.footer .footer-menu {
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer .footer-menu p {
    color: #222222;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 15px;
    border-right: 1px solid #222222;
}

.footer .footer-menu p:last-child {
    border: none;
}

.footer .footer-social {
    position: relative;
    margin-top: 15px;
}

.footer .footer-social a {
    display: inline-block;
}

.footer .footer-social a i {
    margin-right: 15px;
    font-size: 20px;
    color: #222222;
    transition: .3s;
}

.footer .footer-social a:last-child i {
    margin: 0;
}

.footer .footer-social a:hover i {
    color: #414141;
}

.footer .copyright {
    position: relative;
    text-align: center;
    margin-top: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.footer .copyright::before {
    position: absolute;
    content: "";
    width: 50%;
    height: 1px;
    top: 0;
    left: 25%;
    background: rgba(256, 256, 256, .2);
}

.footer .copyright p {
    margin: 0;
}

.footer .copyright .col-md-6:last-child p {
    text-align: right;
}

.footer .copyright p a {
    font-weight: 600;
}

.footer .copyright p a:hover {
    color: #222222;
}

@media (max-width: 575.98px) {
    .footer .footer-info h2 {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
    }

    .footer .footer-info h3 {
        margin-bottom: 20px;
        font-size: 16px;
    }

    .footer .footer-menu p {
        font-size: 16px;
        line-height: 16px;
        padding: 0 5px;
    }
}